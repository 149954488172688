.detail-container {
  margin-top: 1em;
  padding: 20px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #cfcfcf;

  .product-container {
    border-top: solid 0.3px #cacaca;
    padding: 0.3rem;
    img {
      // height: 100%;
    }
  }

  .product-title {
    color: #4163d0;
    font-weight: 700;
    font-size: 16px;
  }

  .title {
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    padding: 0.5rem 0;

    .status {
      font-size: 10px !important;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
    }

    .date {
      font-weight: 400;
      font-size: 12px !important;
    }
  }

  .varian {
    padding: 0.1rem 0.4rem;
    background-color: #9a999956;
    border-radius: 0.5rem;
  }
  .semibold {
    font-weight: 600 !important;
  }
  .section {
    border-bottom: solid 0.3px #cacaca;
    margin-bottom: 1rem;
  }
}

.order-detail {
  display: flex;
  gap: 1rem;

  .total {
    padding: 0.2rem;
    background-color: #4163d0;
    color: white;
    font-weight: 600;
    border-radius: 0.2rem;
  }
}

.order-left {
  width: 60%;
}

.order-right {
  width: 40%;
}

.detail-header-orders {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-header-orders {
  font-weight: 900;
  text-transform: uppercase;
}

.status-header-orders {
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status-header-orders.active {
  color: #22be32;
}

.status-header-orders.draft {
  color: #ff0431;
}

.status-header-orders > img {
  /* width: 35px; */
  height: 15px;
}

.status-order-table {
  width: fit-content;
  font-size: 10px !important;
  padding: 0.3rem 0.7rem;
  border-radius: 0.5rem;
}

.two-col {
  width: 100%;
  display: flex;
}

.chart-wrapper-custom {
  width: 175px;
  height: 175px;
}

.detail-orders {
  display: flex;
  gap: 20px;
}

.detail-orders > div:nth-child(even) {
  /* color: red; */
  flex-grow: 0;
}

.detail-orders > div:nth-child(odd) {
  /* flex-grow: 1; */
  width: 40%;
}

.text-xs {
  font-size: 12px !important;
}
.text-sm {
  font-size: 14px !important;
}
.text-md {
  font-size: 16px !important;
}

.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.grow {
  flex-grow: 1;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.items-end {
  align-items: flex-end;
}
.items-start {
  align-items: flex-start;
}
.width-full {
  width: 100%;
}

.created {
  background-color: #fbe69e;
  color: #e4800a;
}
.waiting_payment {
  background-color: #f9ffae;
  color: #9d8510;
}
.canceled {
  background-color: #dcdcdc;
  color: #848383;
}
.processed {
  background-color: #d4f0fc;
  color: #0f69ab;
}
.sent {
  background-color: #f4ddf6;
  color: #9a15a6;
}
.rejected {
  background-color: #fa5858;
  color: #ffffff;
}
.confirmed {
  background-color: #c7fcc3;
  color: #459f0d;
}
.on_review {
  background-color: #00d19f;
  color: #1c9779;
}
